import { useEffect, useState } from "react";
import "./style.css";
import { addCategory, getCategories, getGroups } from "../../Api";
import CategoryIcon from "@mui/icons-material/Category";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneIcon from "@mui/icons-material/Done";
import { BarChart } from "@mui/x-charts/BarChart";
import notification from "../../Components/Notification";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
const uData = [4000, 3000, 2000];
const pData = [2400, 1398, 9800];
const amtData = [2400, 2210, 2290];
const xLabels = ["Blizzard", "Steam", "Гонки"];

export default function Categories() {
  const [currentGroup, setCurrentGroup] = useState("");
  const [popCat, setPopCat] = useState(false);
  const [newCat, setNewCat] = useState("");
  const [groups, setGroups] = useState([]);
  const [opacity, setOpacity] = useState(true);
  const [showEd, setShowEd] = useState("");
  useEffect(() => {
    getGroups().then((data) => setGroups(data.reverse()));
  }, []);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    groups.length > 0 &&
      getCategories(
        `'${groups
          .map((em) => {
            return em.ID;
          })
          .join("','")}'`
      ).then((data) => {
        setCategories(data);
      });
  }, [groups]);
  return (
    <div className="container categoryPage">
      <div
        className="addNewCategory"
        style={{ transform: popCat ? "translateX(0)" : "translateX(500px)" }}
      >
        <h3>Добавить Категорию</h3>
        <p>
          Вы добавляете новую категорию в группу `
          {currentGroup && currentGroup.Name}`
        </p>
        <input
          type="text"
          value={newCat}
          onChange={(e) => setNewCat(e.target.value)}
          placeholder="Название"
        />
        <div className="opc">
          <input type="checkbox" id="checkOp" />
          <label htmlFor="checkOp" defaultChecked="true">
            Видимость
          </label>
        </div>{" "}
        <div
          className="addCats"
          onClick={() =>
            addCategory(
              currentGroup.ID,
              newCat,
              Number(document.getElementById("checkOp").checked)
            ).then((status) => {
              if (status == 200) {
                notification(
                  "Категории",
                  "Новая категория успешно добавлена",
                  "success"
                );
                setPopCat(false);
                setNewCat("");
                setCurrentGroup("");
                getCategories(
                  `'${groups
                    .map((em) => {
                      return em.ID;
                    })
                    .join("','")}'`
                ).then((data) => {
                  setCategories(data);
                });
              }
            })
          }
        >
          Добавить
        </div>
        <div className="closeCats" onClick={() => setPopCat(false)}>
          Закрыть
        </div>
      </div>
      <div className="groupsBody">
        <h2>Список групп</h2>
        <p>
          Группы подразумевают собой общую черту категорий <br />
          Пример: группа `Одежда`. Подкатегории: `Верхняя одежда`,
          `Кроссовки`,`Джинсы`...
        </p>
        {groups &&
          groups.length > 0 &&
          groups.map((em, index) => {
            return (
              <div className="groupItem" key={"group" + index}>
                <h3>
                  <CategoryIcon />
                  {em.Name}
                  <div className="availibity">Видна</div>
                  <div className="groupActions">
                    <EditIcon />
                    <VisibilityIcon />
                    <DeleteIcon />
                  </div>
                </h3>
                <div className="catsItems">
                  {categories &&
                    categories.length > 0 &&
                    categories.map((cat, index) => {
                      if (em.ID == cat.IdGroup)
                        return (
                          <div
                            className="categoryItemMain"
                            onClick={() => setShowEd(cat.ID)}
                          >
                            <div className="categoryItem" key={"cat" + index}>
                              <b
                                style={{
                                  background:
                                    cat.Opacity == 1
                                      ? "rgb(205, 245, 155)"
                                      : "red",
                                }}
                              ></b>

                              {cat.Name}

                              <div
                                className="editCat"
                                style={
                                  cat.ID == showEd ? { maxWidth: "200px" } : {}
                                }
                              >
                                <EditOutlinedIcon></EditOutlinedIcon>
                                <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                              </div>
                            </div>
                          </div>
                        );
                    })}
                  <div
                    className="addNewCat"
                    onClick={() => {
                      setCurrentGroup(em);
                      setPopCat(true);
                    }}
                  >
                    +
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div>
        <div className="groupRight">
          <h3>Добавить группу</h3>

          <input type="text" placeholder="Название" />
          <div>
            <div className="yes">{opacity && <DoneIcon />}</div>{" "}
            <span>Видимость</span>
          </div>
          <div className="newplan">Добавить</div>
        </div>
        <div className="groupRight">
          <h3>Лучшие категории</h3>
          <span>
            Статистика по трем лучшим категориям вашего магазина (Покупки,
            просмотры)
          </span>
          <BarChart
            height={300}
            series={[
              { data: pData, label: "Посещения", stack: "stack1" },
              { data: amtData, label: "Покупки" },
              { data: uData, label: "Время", stack: "stack1" },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            xAxis={[{ data: xLabels, scaleType: "band" }]}
            {...pieParams}
          />
          <div className="newplan">Полная статистика</div>
        </div>
      </div>
    </div>
  );
}
const pieParams = {
  slotProps: { legend: { hidden: true } },
};
