import { useContext, useState } from "react";
import SelectMultiply from "../../Components/SelectMultiply";
import "./style.css";
import { User } from "../../Contexts/User";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const arr = [
  { Title: "Заявки", ID: "0" },
  { Title: "Пользователи", ID: "1" },
  { Title: "Витрина", ID: "2" },
];
const workers = [
  {
    name: "Михаил Жданков",
    login: "MihailS",
    role: "Директор",
    password: "lsFk2g83$g",
    photo:
      "https://cdn.regnum.ru/uploads/pictures/news/2018/02/05/regnum_picture_1517837462213026_social.jpg",
  },
  {
    name: "Мария Красильникова",
    login: "MariaK",
    role: "Менеджер по заказам",
    password: "L3jsks2%",
    photo:
      "https://static.life.ru/publications/2023/10/31/702721848451.3439.png",
  },
  {
    name: "Николай Воронов",
    login: "NikolayV",
    role: "Менеджер по товарам",
    password: "KSoeMs55",
    photo:
      "https://static-basket-02.wbbasket.ru/vol25/wbkids_articles_editor/avatar/402255/d020077f-856e-4f67-8a90-4b195412d023.jpg",
  },
  {
    name: "Умельцев Павел",
    login: "UmelcevP",
    role: "Маркетолог",
    password: "Drk4%aef",
    photo:
      "https://yt3.googleusercontent.com/ytc/AOPolaT4Nmk_t4_vIMpcpSU5I0bBuR885QcTGd29113v=s900-c-k-c0x00ffffff-no-rj",
  },
];
export default function Workers() {
  const [select, setSelect] = useState("");
  const [user, setUser] = useContext(User);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  function addWorker() {
    const formData = new FormData();
    formData.append("FIO", name);
    formData.append("tel", user.Телефон);
    formData.append("password", password);
    formData.append("email", user.email);
    formData.append("company", user.Компания);
    formData.append("idOwner", user.ID);
    fetch("https://comeback-team.ru/registration.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data == "Ok") {
        } else {
        }
        //document.location.href = "htts://webby-digital.ru/thanks.html";
        //else alert(data);
      });
  }
  return (
    <div className="container workers">
      <h2 className="descTitle">Ваши сотрудники</h2>
      <p className="descText">
        Темы используются для задания расцветки основных блоков в вашем
        магазине. <br />
        Раздел находится в стадии адаптации для администраторов
      </p>
      <div className="workersBody">
        <div className="workersTable">
          <div className="workersTitle workersfr">
            <div></div>
            <div>Имя</div>
            <div></div>
            <div>Роль</div>
            <div>Логин</div>
            <div>Пароль</div>
            <div>Дата создания</div>
            <div>Действия</div>
          </div>
          {workers.map((em) => {
            return (
              <div className="workersGrid workersfr">
                <div className="bubs">
                  <input type="checkbox" name="" id="" />
                </div>
                <div>
                  <img src={em.photo} alt="" />
                </div>
                <div className="userName">{em.name}</div>
                <div className="userName">{em.role}</div>
                <div className="userName">{em.login}</div>
                <div className="userName">{em.password}</div>
                <div className="userName">
                  {new Date().toLocaleDateString() +
                    " " +
                    new Date().toLocaleTimeString()}
                </div>
                <div className="kolot">
                  <EditIcon></EditIcon>
                  <DeleteIcon></DeleteIcon>
                </div>
              </div>
            );
          })}
        </div>
        <div className="addUser">
          <h4>Добавить пользователя</h4>
          <input type="text" placeholder="ФИО" />
          <input type="text" placeholder="Логин" />
          <input type="text" placeholder="Пароль" />
          <div className="addWorker">Добавить</div>
        </div>
      </div>
    </div>
  );
}
