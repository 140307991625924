import { Store } from "react-notifications-component";
import "./style.css";
import React from "react";
export default function notification(title, text, status) {
  Store.addNotification({
    title: title,
    message: text,
    type: status,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__bounceIn"],
    animationOut: ["animate__animated", "animate__backOutUp"],
    dismiss: {
      duration: 4000,
      onScreen: true,
    },
  });
}
