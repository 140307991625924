import { useEffect, useState } from "react";
import { formatDate, getOrder, getOrdersUser } from "../../../Api";
import { OrderCard } from "./OrderCard";
import "./style.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Radial from "../../../GraphicsComp/Radial";
export default function OrderPage() {
  const searchString = new URLSearchParams(window.location.search);
  const [positions, setPositions] = useState();
  const id = searchString.get("key");
  const [userOrders, setUserOrders] = useState();
  const [order, setOrder] = useState();
  useEffect(() => {
    getOrder(id).then((data) => {
      setOrder(data);
    });
  }, []);

  useEffect(() => {
    if (order) {
      setPositions(JSON.parse(order.order.Positions));
    }
  }, [order]);

  return (
    <div className="container orderPage">
      <div className="addOrder">
        <div className="mainBodyOrder">
          <article>Позиции в заказе</article> <div class="navRaz"></div>
          <div className="lookAtOrder">
            <div className="positions">
              {positions &&
                positions.length > 0 &&
                positions.map((em) => {
                  console.log();
                  return <OrderCard id={em.id} len={em.length}></OrderCard>;
                })}
            </div>
          </div>
        </div>
        <div className="ordD">
          <h3>Детали заказа</h3>
          <p>
            <span>Заказ</span> #241342 <span>от </span>
            {(order &&
              order.order &&
              new Date(
                JSON.parse(order.order.timeOrder)
              ).toLocaleDateString()) ||
              ""}
          </p>
          <p>
            <span>Код:</span> KL32SGH
          </p>
          <p>
            <span>Время заказа:</span>{" "}
            {(order &&
              order.order &&
              new Date(
                JSON.parse(order.order.timeOrder)
              ).toLocaleTimeString()) ||
              ""}
          </p>
          <p>
            <span>Количество товаров:</span> {positions && positions.length}
          </p>
          <p>
            <span>Сумма:</span>{" "}
            {order && Number(order.order.summ).toLocaleString()} ₽
          </p>
          <p>
            <span>Статус заказа:</span> {order && order.order.StatusComp}
          </p>
          <div className="vzZaz">Взять заказ</div>
        </div>
        {order && order.user && (
          <div className="additionsOrder">
            <h3>Данные пользователя</h3>
            <div class="navRaz"></div>
            <div className="checkImg">
              <img src={order.user.Photo || ""} alt="" />
              <p>
                {order.user.FIO || ""} <br />
                <span>Новичок</span>
              </p>
            </div>
            <div class="navRaz"></div>
            <p>
              <span>Телефон:</span> {order.user.Phone || ""}{" "}
            </p>
            <p>
              <span>Email:</span> {order.user.Email || ""}{" "}
            </p>
            <p>
              <span>Дата регистрации: </span>
              {new Date(order.user.dateReg).toLocaleDateString() || ""}{" "}
            </p>
            <p>
              <span>Последний визит: </span>
              {new Date().toLocaleDateString() || ""}{" "}
            </p>{" "}
            <p>
              <span>ID пользователя:</span> {order.user.ID || ""}{" "}
            </p>
            <div className="writeTo">Написать сообщение</div>
            <div className="vz">К пользователю</div>
          </div>
        )}
      </div>
    </div>
  );
}
