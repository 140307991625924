import { useEffect, useState } from "react";
import "./style.css";
import {
  getCard,
  getCatsForProduct,
  getGroups,
  newProduct,
  saveChanges,
} from "../../Api";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ClearIcon from "@mui/icons-material/Clear";
import { Rating } from "@mui/material";
import notification from "../../Components/Notification";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClassIcon from "@mui/icons-material/Class";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";

const usersRev = [
  {
    name: "Mr_peppe",
    img: "https://avatars.mds.yandex.net/i?id=05b909a265a86e2ea5db6fd35f3c1eee_l-10464870-images-thumbs&n=13",
    text: "Наиграл уже 14 часов, игра затягивает только в путь! Однозначно посоветую друзьям",
    rating: 5,
  },
  {
    name: "Gigachadik",
    img: "https://lastfm.freetls.fastly.net/i/u/ar0/1f9cd4e406a76fc8bf0720c3dac379bf.jpg",
    text: "Вроде неплохо, но игра довольно короткая",
    rating: 4,
  },
  {
    name: "Михаил Сосенко",
    img: "https://comeback-team.ru/img/8147Wa.png",
    text: "Моя любимая игровая серия, с нетерпением жду новую часть...",
    rating: 5,
  },
];
export default function NewProduct() {
  const urlParams = new URLSearchParams(window.location.search);
  const Navigate = useNavigate();
  const [card, setCard] = useState();
  const [personName, setPersonName] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [categories, setCategories] = useState([]);
  const [catSearch, setCatSearch] = useState("");
  const handleChangeCat = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    getCatsForProduct().then((data) => {
      setCategories(data);
    });
  }, []);
  const handleChange = (e) => {
    setCard({ ...card, [e.target.name]: e.target.value });
  };
  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    card && card.Photo && setPhotos(card.Photo.split(" "));
  }, [card]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getGroups().then((data) => setGroups(data.reverse()));
  }, []);
  //Изменение изображения
  function updateImg(event, index) {
    let arr = card.Photo.split(" ");
    let data = new FormData();
    let path = "https://comeback-team.ru/";
    data.append("file", document.getElementById(event.target.id).files[0]);
    fetch("https://comeback-team.ru/avatar.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let fullPath = path + data;
        arr[index] = fullPath;
        let str = arr.join(" ");
        setCard({ ...card, Photo: str });
        setPhotos(str.split(" "));
      });
  }
  function deleteImg(index) {
    let arr = card.Photo.split(" ").filter((em, num) => num !== index);
    let str = arr.join(" ");
    setCard({ ...card, Photo: str });
    if (str == "") {
      setPhotos([]);
    } else {
      setPhotos(str.split(" "));
    }
  }
  function addImg(event) {
    let formData = new FormData();

    Array.from(document.getElementById("filesadd").files).forEach((file) => {
      formData.append("file[]", file);
    });
    fetch("https://comeback-team.ru/cardsApi/addImg.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data;
        for (let i = 0; i < arr.length; i++) {
          arr[i] = "https://comeback-team.ru/cardsApi/" + arr[i];
        }
        let str = arr.join(" ");
        if (card && card.Photo) {
          setCard({ ...card, Photo: card.Photo + " " + str });
          setPhotos([...photos, ...str.split(" ")]);
        } else {
          setCard({ ...card, Photo: str });
          setPhotos(str.split(" "));
        }
      });
  }
  console.log(photos);
  return (
    <div className="container editProduct">
      <div
        className="catsChoose"
        style={{
          transform: openEditor ? "translateX(0px)" : "translateX(400px)",
        }}
      >
        <ClearIcon
          className="goHe"
          onClick={() => setOpenEditor(false)}
        ></ClearIcon>
        <h3>
          <ClassIcon></ClassIcon> Выберите категории
        </h3>
        <input
          type="text"
          value={catSearch}
          onChange={(e) => setCatSearch(e.target.value)}
          placeholder="Поиск"
        />
        <div className="choosing">
          {groups &&
            groups.length > 0 &&
            groups.map((item) => {
              return (
                <div className="grouping">
                  <h4>{item.Name}</h4>
                  {categories.length > 0 &&
                    categories
                      .filter(
                        (em) =>
                          em.Name.toLowerCase().includes(
                            catSearch.toLowerCase()
                          ) && em.IdGroup == item.ID
                      )
                      .map((em, index) => {
                        return (
                          <div className="catItem" key={index + "k"}>
                            <input
                              type="checkbox"
                              checked={
                                card &&
                                card.Cats &&
                                JSON.parse(card.Cats).includes(em.ID)
                                  ? true
                                  : false
                              }
                              id={"catItem" + index + item.Name}
                              onClick={(e) => {
                                if (!e.target.checked) {
                                  setCard({
                                    ...card,
                                    Cats: JSON.stringify(
                                      JSON.parse(card.Cats).filter(
                                        (item) => item != em.ID
                                      )
                                    ),
                                  });
                                } else {
                                  setCard({
                                    ...card,
                                    Cats: JSON.stringify(
                                      card && card.Cats && card.Cats.length != 0
                                        ? [...JSON.parse(card.Cats), em.ID]
                                        : [em.ID]
                                    ),
                                  });
                                }
                              }}
                            />
                            <label htmlFor={"catItem" + index + item.Name}>
                              <p>{em.Name}</p>
                            </label>
                          </div>
                        );
                      })}
                </div>
              );
            })}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <h2 className="descTitle">Новый продукт</h2>
          <p className="descText">
            Заполните поля, и сохраните изменения <br />
            Ключевые слова используются для удобства поиска товара
          </p>
        </div>
        <div
          className="changeProduct"
          onClick={() => {
            if (card && card.Photo && card.Title && card.Price) {
              newProduct(card).then((status) => {
                if (status == 200) {
                  notification("Товар", "Товар добавлен", "success");
                }
                Navigate(routes.products);
              });
            } else {
              notification(
                "Ошибка",
                "Заполните основые поля: Фото, Название, Цена",
                "success"
              );
            }
          }}
        >
          Добавить продукт
        </div>
      </div>

      <div className="gridEdit">
        <div>
          <div className="imageList">
            <div className="mainImg">
              <div className="imgChange">
                {photos.length > 0 && (
                  <div className="gentChange">
                    <label htmlFor={"fileMain"}>
                      <ChangeCircleIcon></ChangeCircleIcon>
                    </label>
                    <DeleteForeverIcon
                      onClick={() => deleteImg(0)}
                    ></DeleteForeverIcon>
                  </div>
                )}
                <input
                  type="file"
                  id={"fileMain"}
                  onChange={(e) => updateImg(e, 0)}
                />

                {photos.length > 0 ? (
                  <img
                    src={(photos.length > 0 && photos[0]) || ""}
                    alt=""
                  ></img>
                ) : (
                  <div className="noimg"></div>
                )}
              </div>
              <input
                type="file"
                name="file"
                id="filesadd"
                onChange={(e) => {
                  addImg(e);
                }}
                multiple="multiple"
                style={{ display: "none" }}
              />

              <label
                className="addNewPhoto"
                htmlFor="filesadd"
                onClick={(e) => e.stopPropagation()}
              >
                <AddPhotoAlternateIcon /> Добавить изображения
              </label>
            </div>
            <div className="trainImg">
              {photos &&
                photos.length > 0 &&
                photos.map((em, index) => {
                  if (index > 0)
                    return (
                      <div key={index + "img"} className="imgChange">
                        <div className="gentChange">
                          <label htmlFor={"file" + index}>
                            <ChangeCircleIcon></ChangeCircleIcon>
                          </label>
                          <DeleteForeverIcon
                            onClick={() => deleteImg(index)}
                          ></DeleteForeverIcon>
                        </div>
                        <input
                          type="file"
                          id={"file" + index}
                          onChange={(e) => updateImg(e, index)}
                        />

                        <img src={em} />
                      </div>
                    );
                })}
              {photos.length == 0 &&
                [1, 1, 1].map((em, index) => {
                  return <div className="noimg"></div>;
                })}
            </div>
          </div>
        </div>
        <div>
          <div className="lookAtInput">
            <div className="tLsInput">
              <p>Заголовок</p>
              <input
                type="text"
                value={(card && card.Title) || ""}
                name="Title"
                onChange={handleChange}
              />
            </div>
            <div className="tLsInput">
              <p>Цена</p>
              <input
                type="text"
                name="Price"
                value={(card && card.Price) || ""}
                onChange={handleChange}
              />
            </div>{" "}
            <div className="tLsInput">
              <p>Видео</p>
              <input
                type="text"
                value={(card && card.Video) || ""}
                onChange={handleChange}
                name="Video"
              />
            </div>{" "}
            <div className="tLsInput">
              <p>Старая цена</p>
              <input
                type="text"
                name="PriceOld"
                value={
                  (card &&
                    card.PriceOld &&
                    card.PriceOld != "undefined" &&
                    card.PriceOld) ||
                  ""
                }
                onChange={handleChange}
              />
            </div>
            <div className="tLsInput Cats">
              <p>Категории</p>
              <div className="CategoryItems">
                {card &&
                  card.Cats &&
                  categories.length > 0 &&
                  categories
                    .filter((item) => JSON.parse(card.Cats).includes(item.ID))
                    .map((em, index) => {
                      return (
                        <div className="catI" key={index}>
                          <div
                            className="deleteCat"
                            onClick={() => {
                              setCard({
                                ...card,
                                Cats: JSON.stringify(
                                  JSON.parse(card.Cats).filter(
                                    (item) => item != em.ID
                                  )
                                ),
                              });
                            }}
                          >
                            <ClearIcon></ClearIcon>
                          </div>
                          {em.Name}
                        </div>
                      );
                    })}
                <div className="addCat" onClick={() => setOpenEditor(true)}>
                  +
                </div>
              </div>
            </div>
          </div>
          <div className="descChange">
            {" "}
            <div className="tLsInput">
              <p>Ключевые слова</p>
              <textarea
                name="KeyWords"
                id=""
                rows={5}
                onChange={handleChange}
                value={(card && card.KeyWords) || ""}
              ></textarea>
            </div>
          </div>{" "}
          <div className="descChange">
            <div className="tLsInput">
              <p>Описание</p>
              <textarea
                name="Texts"
                id=""
                rows={15}
                onChange={handleChange}
                value={(card && card.Texts) || ""}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
