import "./style.css";
import dogovor from "./dogovor.svg";
import sale from "./bag.svg";
import help from "./ask.svg";
import contacts from "./cotacts.svg";
import profile from "./ava.png";
import { routes } from "../routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../Context";
import { useContext } from "react";
import loops from "./loop.svg";
import noticeA from "./notice.svg";
import notice from "./notN.svg";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
export default function Header() {
  const [context, setContext] = useContext(Context);
  const location = useLocation();
  const Navigate = useNavigate();
  return (
    <div className="header">
      <p className="navigation">
        <HomeIcon></HomeIcon> <p>/ Админ панель /</p>
        <span>
          {location.pathname == routes.products && "Товары"}
          {location.pathname == routes.main && "Главная"}
          {location.pathname == routes.orders && "Заказы"}
          {location.pathname == routes.themes && "Темы"}
          {location.pathname == routes.cssPage && "CSS стили"}
          {location.pathname == routes.users && "Пользователи"}
          {location.pathname == routes.pageList && "Страницы"}
          {location.pathname == routes.newProduct && "Новый продукт"}
          {location.pathname == routes.mainEdit && "Редактирование главной"}
          {location.pathname == routes.profile && "Настройки профиля"}
          {location.pathname == routes.categories && "Группы и категории"}
          {location.pathname == routes.editProduct && "Редактирование товара"}
          {location.pathname.includes("orderDetails") && "Детали заказа"}
          {location.pathname.includes("changePage") &&
            "Редактирование страницы"}
          {location.pathname == routes.createPage && "Новая страница"}
        </span>
      </p>
      {/* <Link to={routes.dogovor} target="_blank">
        <div className="dogovor header_button">
          <img src={dogovor} alt="" />
          <p>Ваш договор</p>
        </div>
      </Link> */}
      <div className="toRight">
        <div className="fromRight">
          <SearchIcon></SearchIcon>
          <input type="text" placeholder="Поиск" />
        </div>{" "}
        <NotificationsIcon></NotificationsIcon>
        <SettingsIcon></SettingsIcon>
        <div className="avt"></div>
      </div>

      {/*  <div className="searchM">
        <input type="text" placeholder="Поиск товара..." />
        <img src={loops} alt="" />
      </div> */}
      {/*   <img src={notice} alt="" /> */}
      {/*  <div className="profileSt">
        <div className="profileName">
          <p>Сосенко Михаил</p>
          <span>Руководитель</span>
        </div>
        <div
          alt=""
          className="profile"
          onClick={() => {
            Navigate(routes.login);
            localStorage.clear();
            window.location.reload();
          }}
        />
      </div> */}
    </div>
  );
}
